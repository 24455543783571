/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import Modal from './components/Modal'
import BiodataTabs from './components/BiodataTab'
import AccountTab from './components/AccountTab'
import CompetencyTab from './components/CompetencyTab'
import FinanceTab from './components/FinanceTab'
import ActivityTab from './components/ActivityTab'
import {useCookies} from 'react-cookie'
import {getPerson} from './core/_requests'
import {DataPerson} from './core/_models'

type dataPerson = {
  id: number
  name: string
  email: string
  phone_number: string
}

const Overview: FC = () => {
  const [cookies, setCookie, removeCookies] = useCookies(['name', '_bt'])
  const [data, setData] = useState<dataPerson>({id: 0, name: '', email: '', phone_number: ''})

  useEffect(() => {
    getPerson(cookies._bt)
      .then((res) => {
        setData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between w-100 mb-10'>
        <div className='d-flex align-items-center gap-3'>
          <div className='cursor-pointer symbol symbol-50px'>
            <img
              className='rounded-circle'
              src={toAbsoluteUrl('/media/avatars/blank.png')}
              alt=''
            />
          </div>
          <div className='fs-2 fw-bold'>{data.name}</div>
          <div className=''>
            <KTIcon iconName='verify' iconType='solid' className='text-success fs-1' />
          </div>
        </div>
        <div className='d-flex gap-3'>
          <button
            className='btn btn-icon btn-outline btn-outline-primary btn-active-light-primary border-2 w-auto px-5'
            data-bs-toggle='modal'
            data-bs-target='#digital-card'
          >
            <i className='fa-solid fa-share-nodes fs-2 me-2'></i> Kartu Nama Digital
          </button>
          <button
            className='btn btn-icon btn-outline btn-outline-primary btn-active-light-primary border-2 px-5'
            data-bs-toggle='modal'
            data-bs-target='#wallet-modal'
          >
            <i className='fa-solid fa-wallet fs-2' />
          </button>
        </div>
      </div>

      {/* begin::Nav Tabs || Tabs Link */}
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
        {['Data Diri', 'Akun', 'Kompetensi', 'Keuangan', 'Aktivitas'].map((title, i) => (
          <li key={i + 1} className='nav-item'>
            <a
              className={i == 0 ? 'nav-link active' : 'nav-link'}
              data-bs-toggle='tab'
              href={`#tab_pane_${i + 1}`}
            >
              {title}
            </a>
          </li>
        ))}
      </ul>
      {/* end::Nav Tabs || Tabs Link */}

      {/* begin::Tabs Content */}
      <div className='tab-content' id='myTabContent'>
        <BiodataTabs data={data} token={cookies._bt} />
        <AccountTab data={data} token={cookies._bt} />
        <CompetencyTab />
        <FinanceTab />
        <ActivityTab />
      </div>
      {/* end::Tabs Content */}

      {/* Modal */}
      <Modal />
    </>
  )
}

export default Overview
