import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const LOCAL_API_URL = process.env.REACT_APP_LOCAL_API_URL
export const API_TOKEN = process.env.REACT_APP_API_TOKEN

export const LOGOUT_URL = `${API_URL}/logout`

// Get Endpoint
export const GET_PERSON_URL = `${API_URL}/person`
export const GET_TIMEZONE_URL = `${API_URL}/timezone`
export const GET_CURRENCIES_URL = `${API_URL}/currencies`
export const GET_BANKS_URL = `${API_URL}/banks`
export const GET_PERSON_SKILLS_URL = `${API_URL}/person/skills`
export const GET_SKILLS_URL = `${API_URL}/skills`
export const GET_CITIES_URL = `${API_URL}/cities`
export const GET_ACTIVITIES_URL = `${API_URL}/person/activities`
export const GET_PERSON_BANK_URL = `${API_URL}/person/bank/accounts`
export const GET_BILLING_ADDRESS_URL = `${API_URL}/person/finance/addresses`

// Post Endpoint
export const STORE_DATA_PERSON_URL = `${API_URL}/person/store`
export const STORE_BANK_ACCOUNT_URL = `${API_URL}/person/finance/store`
export const STORE_BILLING_ADDRESS_URL = `${API_URL}/person/finance/address/store`
export const STORE_SKILL_URL = `${API_URL}/skill/store`
export const SEND_OTP_OLD_EMAIL_URL = `${API_URL}/otp/send/old-email`
export const SEND_OTP_NEW_EMAIL_URL = `${API_URL}/otp/send/new-email`
export const CHECK_OTP_OLD_EMAIL_URL = `${API_URL}/otp/check/email`
export const CHANGE_EMAIL_URL = `${API_URL}/email/change`
export const CHANGE_PHONE_NUMBER_URL = `${API_URL}/phone/change`
export const CHANGE_PASSWORD_URL = `${API_URL}/password/change`
export const DEACTIVATE_ACCOUNT_URL = `${API_URL}/account/nonactivate`
export const DELETE_PERSON_SKILL_URL = `${API_URL}/person/skill/destroy`

// Account Tab
export const MEDIA_COMMUNICATION_EMAIL_URL = `${API_URL}/person/change/is-can-sent-by-email`
export const MEDIA_COMMUNICATION_PHONE_URL = `${API_URL}/person/change/is-can-be-called`
export const UPDATE_TIMEZONE_URL = `${API_URL}/person/timezone/update`
export const FINANCIAL_EMAIL_URL = `${API_URL}/person/change/is-financial-info-by-email`
export const FINANCIAL_PHONE_URL = `${API_URL}/person/change/is-financial-info-by-phone`
export const JOB_EMAIL_URL = `${API_URL}/person/change/is-job-info-by-email`
export const JOB_PHONE_URL = `${API_URL}/person/change/is-job-info-by-phone`
export const MARKETING_EMAIL_URL = `${API_URL}/person/change/is-marketing-info-by-email`
export const MARKETING_PHONE_URL = `${API_URL}/person/change/is-marketing-info-by-phone`

export const SEND_OTP_EMAIL_URL = `${API_URL}/currencies`
axios.defaults.headers.Accept = 'application/json'

export function getPerson(token: any) {
  return axios.get(GET_PERSON_URL, {
    headers: {
      'x-api-token': API_TOKEN,
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getTimezone() {
  return axios.get(GET_TIMEZONE_URL)
}

export function getCurrencies() {
  return axios.get(GET_CURRENCIES_URL)
}

export function getBanks() {
  return axios.get(GET_BANKS_URL)
}

export function getSkills() {
  return axios.get(GET_SKILLS_URL)
}

export function getPersonSkills(token: string) {
  return axios.get(GET_PERSON_SKILLS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getCities() {
  return axios.get(GET_CITIES_URL)
}

export function getPersonBank(token: string) {
  return axios.get(GET_PERSON_BANK_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getActivities(token: string) {
  return axios.get(GET_ACTIVITIES_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getBillingAddress(token: string) {
  return axios.get(GET_BILLING_ADDRESS_URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// logout
export function logout(token: string) {
  return axios.post(
    LOGOUT_URL,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function storePerson(
  fullname: string,
  nik: string,
  birthplace: string,
  birthdate: string,
  domicile: string,
  gender: string,
  token: string
) {
  return axios.post(
    STORE_DATA_PERSON_URL,
    {fullname, nik, birthplace, birthdate, domicile, gender},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function sendOtpOldEmail(email: string, token: string) {
  return axios.post(
    SEND_OTP_OLD_EMAIL_URL,
    {email},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function checkOtpOldEmail(email: string, code: string, token: string) {
  return axios.post(
    CHECK_OTP_OLD_EMAIL_URL,
    {email, code},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function sendOtpNewEmail(email: string, token: string) {
  return axios.post(
    SEND_OTP_NEW_EMAIL_URL,
    {new_email: email},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function changeEmail(new_email: string, code: string, token: string) {
  return axios.post(
    CHANGE_EMAIL_URL,
    {new_email, code},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function changePhoneNumber(phoneNumber: string, token: string) {
  return axios.post(
    CHANGE_PHONE_NUMBER_URL,
    {phone_number: phoneNumber},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function changePassword(password: string, password_confirmation: string, token: string) {
  return axios.post(
    CHANGE_PHONE_NUMBER_URL,
    {password, password_confirmation},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function nonActivateAccount(email: string, message: string, token: string) {
  return axios.post(
    DEACTIVATE_ACCOUNT_URL,
    {email, message},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function storeBankAccount(
  bank_id: number,
  bank_account_number: string,
  bank_account_owner_name: string,
  token: string
) {
  return axios.post(
    STORE_BANK_ACCOUNT_URL,
    {bank_id, bank_account_number, bank_account_owner_name},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function storeBillingAddress(
  finance_id: number,
  name: string,
  phone_number: string,
  address: string,
  token: string
) {
  return axios.post(
    STORE_BILLING_ADDRESS_URL,
    {finance_id, name, phone_number, address, token},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function storeSkill(skill_id: string, skill_name: string, token: string) {
  return axios.post(
    STORE_SKILL_URL,
    {skill_id, skill_name},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export function deletePersonSkill(skill_id: string, token: string) {
  return axios.post(
    DELETE_PERSON_SKILL_URL,
    {skill_id},
    {
      headers: {
        'x-api-token': API_TOKEN,
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
