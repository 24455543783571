import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {deletePersonSkill, getPersonSkills, getSkills, storeSkill} from '../core/_requests'
import Select from 'react-select'
import {useCookies} from 'react-cookie'
import {Modal} from 'react-bootstrap'

const CompetencyTab = () => {
  const competenciesData = [
    {
      name: 'Public Speaking',
      id: 1,
    },
    {
      name: 'Database',
      id: 2,
    },
    {
      name: 'Figma',
      id: 3,
    },
  ]

  const [competencies, setCompetencies] = useState(competenciesData)
  const [competencyInputValue, setCompetencyInputValue] = useState<string>('')

  const [skills, setSkills] = useState<any>()
  const [skill, setSkill] = useState<any>()
  const [personSkills, setPersonSkills] = useState([])
  const [cookies] = useCookies(['_bt'])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [addCompetencyModal, setAddCompetencyModal] = useState(false)

  async function getSkillsHandler() {
    try {
      const {data: skills} = await getSkills()
      const testing = Object.keys(skills.data).map((key) => ({
        value: key,
        label: skills.data[key],
      }))
      setSkills(testing)
    } catch (error) {
      console.log('data kompetensi gagal diambil')
      setSkills([{value: '0', label: 'Kompetensi'}])
    }
  }

  async function getPersonSkillsHandler() {
    try {
      const {data: personSkills} = await getPersonSkills(cookies._bt)
      setPersonSkills(personSkills.data)
    } catch (error) {
      console.log(error)
      console.log('data kompetensi gagal diambil')
    }
  }

  useEffect(() => {
    getSkillsHandler()
    getPersonSkillsHandler()
  }, [])

  async function setSkillHandler() {
    setLoading(true)
    setAddCompetencyModal(false)
    setSuccess('')
    try {
      const {data} = await storeSkill(skill.value, skill.label, cookies._bt)
      setLoading(false)
      setSuccess(data.data.message)
    } catch (error: any) {
      setError(error.response.data.message)
      setLoading(false)
      setAddCompetencyModal(true)
    }
  }

  async function deletePersonSkillHandler(skillId) {
    setLoading(true)
    setAddCompetencyModal(false)
    setSuccess('')
    try {
      const {data} = await deletePersonSkill(skillId, cookies._bt)
      setLoading(false)
      setSuccess(data.message)
    } catch (error: any) {
      setError(error.response.data.message)
      setLoading(false)
      setAddCompetencyModal(true)
    }
  }

  return (
    <>
      <div className='tab-pane fade' id='tab_pane_3' role='tabpanel'>
        {/* begin::Success Message */}
        {success && (
          <div
            className='alert alert-success d-flex justify-content-between align-items-center'
            role='alert'
          >
            <div className='d-flex align-items-center gap-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
              <div className='text-dark'>{success}</div>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setSuccess('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        )}
        {/* end::Success Message */}

        {/* begin::Error Message */}
        {error && (
          <div
            className='alert alert-danger d-flex justify-content-between align-items-center'
            role='alert'
          >
            <div className='d-flex align-items-center gap-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{error}</div>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setError('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        )}
        {/* end::Error Message */}

        <div className='card card-custom'>
          <div className='card-body'>
            <div className='d-flex justify-content-between align-items-center'>
              <h2>Kompetensi</h2>
              <button className='btn btn-primary' onClick={() => setAddCompetencyModal(true)}>
                Tambah
              </button>
            </div>
            {personSkills.map((skill: any) => (
              <div
                key={skill?.skill_id}
                className='d-flex justify-content-between align-items-center py-5 border-bottom border-2'
              >
                <div>{skill?.skill_name}</div>
                <button className='btn' onClick={() => deletePersonSkillHandler(skill.skill_id)}>
                  <KTIcon iconName='trash' iconType='solid' className='text-danger fs-1' />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* begin:: Add Competency Modal */}
      <Modal show={addCompetencyModal}>
        <div className='modal-content'>
          <div className='modal-header border-0'>
            <h5 className='modal-title'>Tambah Kompetensi</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setAddCompetencyModal(false)}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Select
              options={skills}
              className='mb-5'
              isSearchable={true}
              placeholder='Nama Kompetensi'
              onChange={(value) => {
                setSkill(value)
              }}
            />
            <div className='mb-5'>
              <button className='btn btn-primary w-100' onClick={setSkillHandler} disabled={!skill}>
                Tambah
              </button>
            </div>
          </div>
          <div className='modal-footer border-0 pt-0'></div>
        </div>
      </Modal>
      {/* end:: Add Competency Modal */}
    </>
  )
}

export default CompetencyTab
