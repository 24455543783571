import {useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {nonActivateAccount} from '../../core/_requests'
import {useCookies} from 'react-cookie'
import {logout} from '../../core/_requests'

type DeactivateAccount = {}

type Props = {
  data: {
    email: string
  }
}

const DeactivateAccount = (props: Props) => {
  const [inputModal, setInputModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [message, setMessage] = useState('')
  const [cookies, setCookies, removeCookies] = useCookies(['_bt'])
  const [errorMsg, setErrorMsg] = useState('')

  async function deactivateHandler() {
    setErrorMsg('')
    if (message == 'non-aktifkan akun saya') {
      setInputModal(false)
      try {
        const {data} = await nonActivateAccount(props.data.email, message, cookies._bt)
        console.log(data)
        setSuccessModal(true)
      } catch (error: any) {
        console.log(error)
        setErrorMsg(error.response.data.message)
        setInputModal(true)
      }
    } else {
      setErrorMsg('Verifikasi yang anda masukkan salah')
    }
  }

  function logoutHandler() {
    removeCookies('_bt', undefined)
    setCookies('_bt', null)
  }

  return (
    <>
      <div className=''>
        <div className='fs-4 fw-bold mb-5'>Non-Aktifkan Akun</div>
        <div className='border border-2 border-danger rounded-2 p-5'>
          <div className='fs-5 mb-2'>
            Akun yang di non-aktifkan akan otomatis terhapus dalam waktu 30 hari !
          </div>
          <button
            type='button'
            className='btn btn-sm btn-outline btn-outline-danger border-2'
            onClick={() => setInputModal(!inputModal)}
          >
            Non-Aktifkan Akun
          </button>
        </div>
      </div>

      {/* begin:: Input Modal */}
      <Modal show={inputModal}>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Non-Aktifkan Akun</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setInputModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          {/* begin::Success Message */}
          {errorMsg && (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{errorMsg}</div>
            </div>
          )}
          {/* end::Success Message */}

          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='email-input'
                type='email'
                placeholder='testing@example.com'
                className='form-control bg-transparent'
                value={props.data.email}
                readOnly
                disabled
              />
              <label htmlFor='email-input' className='form-label'>
                Email Anda
              </label>
            </div>
          </div>
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='verification-input'
                type='text'
                placeholder='verif'
                className='form-control form-sm bg-transparent'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <label htmlFor='email-input' className='form-label'>
                Untuk verifikasi, ketik{' '}
                <span className='text-danger'>
                  <i>non-aktifkan akun saya</i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button className='btn btn-primary w-100' disabled={!message} onClick={deactivateHandler}>
            Selanjutnya
          </button>
        </div>
      </Modal>
      {/* end:: Input Modal */}

      <Modal
        show={successModal}
        onHide={() => {
          setSuccessModal(false)
          logoutHandler()
        }}
      >
        <div className='modal-header d-block border-0 pb-0'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='modal-title'>Akun ini berhasil di non-aktifkan</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => {
                setSuccessModal(false)
                logoutHandler()
              }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='text-gray-600 fs-7'>
            Kami sedih kehilangan mu, tapi tenang akun yang di non-aktifkan{' '}
            <span className='fw-bold'>dapat dikembalikan</span>
            sebelum terhapus otomatis dalam waktu 30 hari !
          </div>
        </div>
        <div className='modal-body'>
          <img
            className='w-100'
            src={toAbsoluteUrl('/media/diawan/non-activate-account-element-1.svg')}
            alt='non activate account image'
          />
        </div>
        <div className='modal-footer border-0 justify-content-center pt-0'>
          <button onClick={logoutHandler} className='btn btn-primary'>
            Daftar
          </button>
        </div>
      </Modal>
    </>
  )
}

export default DeactivateAccount
