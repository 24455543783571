import {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import {KTIcon} from '../../../../_metronic/helpers'
import {getActivities} from '../core/_requests'
import {useCookies} from 'react-cookie'

type Activities = {
  current_page: number
  data: []
  first_page_url: string
  from: string
  last_page_url: string
  next_page_url: string
  per_page: number
  prev_page_url: string
  to: string
  total: number
}

const ActivityTab = () => {
  const [dateState, setDateState] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [cookies] = useCookies(['_bt'])
  const [activities, setActivities] = useState<Activities>()

  async function getActivitiesHandler() {
    try {
      const {data: activities} = await getActivities(cookies._bt)
      setActivities(activities.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getActivitiesHandler()
  }, [])

  return (
    <div className='tab-pane fade' id='tab_pane_5' role='tabpanel'>
      <div className='mb-8'>
        <div className='fs-4 fw-bold'>Aktivitas Ku</div>
        <div className=''>Catatan aktivitas mu dalam sistem Diawan</div>
      </div>
      <button className='btn btn-white text-primary mb-8'>
        <i className='fa fa-arrow-down icon-xs text-primary'></i> Export
      </button>
      <div className='card card-custom'>
        <div className='card-body'>
          <div className='d-flex justify-content-end'>
            <div className='w-auto'>
              <label className='input-group form-control cursor-pointer p-0' htmlFor='date-picker'>
                <Flatpickr
                  id='date-picker'
                  value={dateState.date}
                  onChange={([startDate, endDate]) => {
                    setDateState({startDate, endDate})
                  }}
                  options={{
                    mode: 'range',
                  }}
                  className='form-control cursor-pointer border-0'
                  placeholder='Pick date'
                />
                <span className='input-group-text bg-transparent border-0' id='basic-addon2'>
                  <KTIcon iconName='calendar' iconType='solid' className='fs-1 cursor-pointer' />
                </span>
              </label>
            </div>
          </div>
          <div className='py-5'>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-80'>
                  <th>Aktivitas</th>
                  <th>Tanggal</th>
                </tr>
              </thead>
              <tbody>
                {activities?.data?.map((value) => (
                  <tr>
                    <td>
                      Lorem ipsum dolor sit amet consectetur. Aenean velit semper orci morbi.
                      Faucibus amet pretium ridiculus ipsum ipsum quis.
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* begin:Pagination */}
            <div className='d-flex justify-content-end align-items-center gap-5'>
              <div className='d-flex align-items-center'>
                <div className='fs-7 me-1 text-gray-600'>Rows per-pages :</div>
                <select className='form-select form-select-sm w-auto border-0' disabled>
                  <option value='10'>{activities?.per_page}</option>
                </select>
              </div>
              <div className='fs-7'>1-10 of 10</div>
              <div className='d-flex flex-wrp py-2 mr-3'>
                <a
                  href={activities?.prev_page_url}
                  className='btn btn-icon btn-sm btn-white mr-2 my-1'
                >
                  <i className='fa fa-chevron-left icon-xs text-dark'></i>
                </a>
                <a
                  href={activities?.next_page_url}
                  className='btn btn-icon btn-sm btn-white mr-2 my-1'
                >
                  <i className='fa fa-chevron-right icon-xs text-dark'></i>
                </a>
              </div>
            </div>
            {/* end:Pagination */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityTab
