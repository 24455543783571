import React, {useState} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

const Modal = () => {
  const [hiddenPass, setHiddenPass] = useState(true)
  return (
    <>
      {/* begin::Digital Card Modal */}
      <div
        className='ms-20 modal modal-lg fade'
        id='digital-card'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Kartu Nama Digital</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='d-flex gap-5'>
                {/* basic */}
                <div className='w-50 h-auto'>
                  <div className='card card-custom border border-2 border-primary'>
                    <div className='card-header'>
                      <div className='card-title justify-content-center flex-wrap gap-1 w-100'>
                        <span className='badge bg-primary bg-opacity-75 text-white fs-base fw-normal rounded-pill mb-8  px-3'>
                          Basic
                        </span>
                        <div className='text-center w-100'>
                          <div className='cursor-pointer symbol symbol-40px'>
                            <img
                              className='rounded-circle'
                              src={toAbsoluteUrl('/media/avatars/blank.png')}
                              alt='profile picture'
                            />
                          </div>
                        </div>
                        <h5 className='fs-2 text-warning fw-bold w-100 text-center'>
                          Annika Visser
                        </h5>
                        <h6 className='fs-6 w-100 text-center'>Freelancer</h6>
                      </div>
                    </div>
                    <div className='card-body px-5 py-0'>
                      <div className='border-top py-5'>
                        "Everyone thinks of changing the world, but no one thinks of changing
                        himself."
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='geolocation' iconType='solid' className=' fs-1' />
                        <div className='fs-5 fw-bold'>Jakarta</div>
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='briefcase' iconType='solid' className=' fs-1' />
                        <div className='fs-5'>
                          <span className='fw-bold'>Product Designer at Devias IO</span>
                          <br />
                          Past: UX Designer
                        </div>
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='sms' iconType='solid' className=' fs-1' />
                        <div className='fs-5 fw-bold'>annika.visser@gmail.com</div>
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='phone' iconType='solid' className=' fs-1' />
                        <div className='fs-5 fw-bold'>580-656-6537</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* professional */}
                <div className='w-50'>
                  <div className='card card-custom mb-3'>
                    <div className='card-header'>
                      <div className='card-title justify-content-center flex-wrap gap-1 w-100'>
                        <span className='badge bg-success text-white fs-base fw-normal rounded-pill mb-8 px-3'>
                          Professional
                        </span>
                        <div className='text-center w-100'>
                          <div className='cursor-pointer symbol symbol-40px'>
                            <img
                              className='rounded-circle'
                              src={toAbsoluteUrl('/media/avatars/blank.png')}
                              alt='profile picture'
                            />
                          </div>
                        </div>
                        <h5 className='fs-2 text-warning fw-bold w-100 text-center'>
                          Annika Visser
                        </h5>
                        <h6 className='fs-6 w-100 text-center'>Freelancer</h6>
                      </div>
                    </div>
                    <div className='card-body px-5 py-0'>
                      <div className='border-top py-5'>
                        "Everyone thinks of changing the world, but no one thinks of changing
                        himself."
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='geolocation' iconType='solid' className=' fs-1' />
                        <div className='fs-5 fw-bold'>Jakarta</div>
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='briefcase' iconType='solid' className=' fs-1' />
                        <div className='fs-5'>
                          <span className='fw-bold'>Product Designer at Devias IO</span>
                          <br />
                          Past: UX Designer
                        </div>
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='sms' iconType='solid' className=' fs-1' />
                        <div className='fs-5 fw-bold'>annika.visser@gmail.com</div>
                      </div>
                      <div className='d-flex align-items-center gap-5 border-top py-5'>
                        <KTIcon iconName='phone' iconType='solid' className=' fs-1' />
                        <div className='fs-5 fw-bold'>580-656-6537</div>
                      </div>
                      <div className='py-5'>
                        <button className='btn btn-sm btn-primary w-100'>Download CV</button>
                      </div>
                    </div>
                  </div>
                  <span>* Terbatas untuk PM & Pakar.</span>
                </div>
              </div>
            </div>
            <div className='modal-footer border-0 pt-0'>
              <div className='d-flex w-100'>
                <div className='input-group input-group-solid border border-3 border-primary'>
                  <div className='form-floating'>
                    <input
                      id='digital-card-link'
                      type='text'
                      placeholder='name'
                      className='form-control bg-transparent border-0'
                      value='https://diawan.co.id/kartu-nama-digital/e12h89e'
                      readOnly
                    />
                    <label htmlFor='digital-card-link' className='form-label text-primary'>
                      Link Kartu Nama Digital
                    </label>
                  </div>
                  <span className='input-group-text bg-transparent border-0' id='basic-addon2'>
                    <KTIcon iconName='copy' iconType='solid' className='fs-1 cursor-pointer' />
                  </span>
                </div>
                <div className='px-4 d-flex justify-content-center align-items-center fw-bolder'>
                  {/* <i className='bi bi-download fs-2x fw-bolder text-primary'></i> */}
                  <i className='fas fa-download fs-1 fw-bolder text-primary'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Digital Card Modal */}

      {/* begin::Wallet Modal */}
      <div
        className='ms-20 modal fade'
        id='wallet-modal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex={-1}
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Dompet</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <label className='form-label fw-bolder text-dark mb-0'>Saldo Dura</label>
              <div className='input-group input-group-solid mb-5'>
                <input
                  type={hiddenPass ? 'password' : 'text'}
                  autoComplete='off'
                  className='form-control bg-transparent border-0 p-0 fs-2'
                  value='102991'
                  readOnly
                />
                <span
                  className='input-group-text bg-transparent border-0'
                  id='basic-addon2'
                  onClick={() => setHiddenPass(!hiddenPass)}
                >
                  <KTIcon
                    iconName={hiddenPass ? 'eye-slash' : 'eye'}
                    iconType='solid'
                    className='text-primary fs-1 cursor-pointer'
                  />
                </span>
              </div>
              <div className='d-flex gap-5 mb-5'>
                <div className=''>
                  <label className='form-label fw-bolder text-success fs-6 mb-0'>Debit</label>
                  <input
                    type={hiddenPass ? 'password' : 'text'}
                    autoComplete='off'
                    className='form-control bg-transparent border-0 p-0 fs-2'
                    value='128'
                    readOnly
                  />
                </div>
                <div className=''>
                  <label className='form-label fw-bolder text-danger fs-6 mb-0'>Kredit</label>
                  <input
                    type={hiddenPass ? 'password' : 'text'}
                    autoComplete='off'
                    className='form-control bg-transparent border-0 p-0 fs-2'
                    value='238'
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className='modal-footer border-0 pt-0'></div>
          </div>
        </div>
      </div>
      {/* end::Wallet Modal */}
    </>
  )
}

const ComingSoonFeatureModal = () => {
  return (
    <div className='ms-20 modal fade' id='coming-soon-feature-modal' tabIndex={-1}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <div>Fitur ini akan segera hadir</div>
          </div>
          <div className='modal-footer border-0 pt-0'></div>
        </div>
      </div>
    </div>
  )
}

export default Modal
export {ComingSoonFeatureModal}
