import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {KTSVG, KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {DataPerson} from '../core/_models'
import {getCities, storePerson} from '../core/_requests'
import {useCookies} from 'react-cookie'

type Props = {
  data: any
  token: string
}

const initialValues = {
  fullName: '',
  idNumber: '',
  birthPlace: '',
  birthDate: '',
  domicile: '',
  gender: '',
}

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Nama lengkap wajib diisi'),
  idNumber: Yup.string().nullable(),
  domicile: Yup.string().required('Kota domisili wajib diisi'),
  birthPlace: Yup.string().required('Tempat lahir wajib diisi'),
  birthDate: Yup.string().required('Tanggal lahir wajib diisi'),
  gender: Yup.string().required('Jenis kelamin wajib diisi'),
})

const BiodataTabs = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [cookies] = useCookies(['_bt'])
  const [succesMsg, setSuccesMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [cities, setCities] = useState([])

  useEffect(() => {
    if (props.data.person) {
      formik.setFieldValue('fullName', props.data.person.fullname)
      formik.setFieldValue('idNumber', props.data.person.nik)
    }
  }, [props])

  // get cities handler
  async function getCitiesHandler() {
    try {
      const {data: cities} = await getCities()
      setCities(cities.data)
    } catch (error) {
      console.log(error)
      console.log('data cities gagal diambil')
    }
  }

  useEffect(() => {
    getCitiesHandler()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      setSuccesMsg('')
      try {
        setLoading(false)
        const {data} = await storePerson(
          values.fullName,
          values.idNumber,
          values.birthPlace,
          values.birthDate,
          values.domicile,
          values.gender,
          cookies._bt
        )
        setSuccesMsg(data.message)
      } catch (error: any) {
        setLoading(false)
        setErrorMsg(error.data.message)
      }
    },
  })

  return (
    <div className='tab-pane fade active show' id='tab_pane_1' role='tabpanel'>
      {/* begin::Success Message */}
      {succesMsg && (
        <div className='alert alert-success d-flex align-items-center gap-3' role='alert'>
          <KTSVG
            path='/media/icons/duotune/general/gen043.svg'
            className='svg-icon svg-icon-2x svg-icon-success'
          />
          <div className='text-dark'>Data diri berhasil disimpan</div>
        </div>
      )}
      {/* end::Success Message */}

      {/* begin::Error Message */}
      {errorMsg && (
        <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
          <KTSVG
            path='/media/icons/duotune/general/gen044.svg'
            className='svg-icon svg-icon-2x svg-icon-danger'
          />
          <div className='text-dark'>{errorMsg}</div>
        </div>
      )}
      {/* end::Success Message */}

      <div className='d-flex flex-wrap-reverse flex-md-nowrap gap-15'>
        {/* begin::Profile Form */}
        <div className='card card-custom w-100 w-md-75'>
          <form className='form' onSubmit={formik.handleSubmit}>
            <div className='card-body'>
              {/* begin::Profile Photo Input */}
              <div className='d-flex align-items-center gap-5 mb-5'>
                <div className='cursor-pointer symbol symbol-40px'>
                  <img
                    className='rounded-circle'
                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                    alt=''
                  />
                </div>
                <input className='d-none' id='profile-photo-input' type='file' />
                <label
                  className='cursor-pointer text-warning fw-bold'
                  htmlFor='profile-photo-input'
                >
                  Pilih Foto
                </label>
              </div>
              {/* end::Profile Photo Input */}

              {/* begin::Name Input */}
              <div className='mb-5'>
                <div className='form-floating'>
                  <input
                    id='full-name-input'
                    type='text'
                    placeholder='name'
                    {...formik.getFieldProps('fullName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.fullName && formik.errors.fullName},
                      {
                        'is-valid': formik.touched.fullName && !formik.errors.fullName,
                      }
                    )}
                  />
                  <label htmlFor='full-name-input' className='form-label'>
                    Nama Lengkap
                  </label>
                </div>
                {formik.touched.fullName && formik.errors.fullName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.fullName}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Name Input */}

              {/* begin::ID Number (KTP) Input */}
              <div className='mb-5'>
                <div className='form-floating'>
                  <input
                    id='idNumber'
                    type='text'
                    placeholder='name'
                    {...formik.getFieldProps('idNumber')}
                    className='form-control bg-transparent'
                  />
                  <label htmlFor='idNumber' className='form-label'>
                    Nomor Identitas (KTP)
                  </label>
                </div>
                <div className='fv-help-block pt-1 ps-4'>
                  <span className='fs-7 text-gray-500'>
                    Wajib diisi jika kamu ingin menjadi client, freelancer dan PM
                  </span>
                </div>
                {formik.touched.idNumber && formik.errors.idNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.idNumber}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::ID Number (KTP) Input */}

              {/* begin::Birth Place & Birth Date Input */}
              <div className='d-flex gap-5 mb-5'>
                <div className='w-50'>
                  <div className='form-floating'>
                    <div className='form-floating'>
                      <select
                        id='birth-place'
                        {...formik.getFieldProps('birthPlace')}
                        className={clsx(
                          'form-select bg-transparent',
                          {'is-invalid': formik.touched.birthPlace && formik.errors.birthPlace},
                          {
                            'is-valid': formik.touched.birthPlace && !formik.errors.birthPlace,
                          }
                        )}
                      >
                        <option>Pilih tempat lahir</option>
                        {cities.map((city: {value: string; label: string}) => (
                          <option key={city.value} value={city.value}>{city.label}</option>
                        ))}
                      </select>
                      <label htmlFor='birth-place' className='form-label'>
                        Tempat Lahir
                      </label>
                    </div>
                  </div>
                  {formik.touched.birthPlace && formik.errors.birthPlace && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.birthPlace}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='w-50'>
                  <div className='form-floating'>
                    <input
                      id='birthDate'
                      type='date'
                      placeholder='name'
                      {...formik.getFieldProps('birthDate')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.birthDate && formik.errors.birthDate},
                        {
                          'is-valid': formik.touched.birthDate && !formik.errors.birthDate,
                        }
                      )}
                    />
                    <label htmlFor='birthDate' className='form-label'>
                      Tanggal Lahir
                    </label>
                  </div>
                  {formik.touched.birthDate && formik.errors.birthDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.birthDate}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* end::Birth Place & Birth Date Input */}

              {/* begin::Residence City Input */}
              <div className='mb-5'>
                <div className='form-floating'>
                  <select
                    id='residence-city-input'
                    {...formik.getFieldProps('domicile')}
                    className={clsx(
                      'form-select bg-transparent',
                      {'is-invalid': formik.touched.domicile && formik.errors.domicile},
                      {
                        'is-valid': formik.touched.domicile && !formik.errors.domicile,
                      }
                    )}
                  >
                    <option>Pilih kota domisili</option>
                    {cities.map((city: {value: string; label: string}) => (
                      <option key={city.value} value={city.value}>{city.label}</option>
                    ))}
                  </select>
                  <label htmlFor='residence-city-input' className='form-label'>
                    Kota Domisili
                  </label>
                </div>
                {formik.touched.domicile && formik.errors.domicile && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.domicile}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Residence City Input */}

              {/* begin::Gender Input */}
              <div className='mb-5'>
                <label className='fs-6 fw-medium text-dark mb-3'>Jenis Kelamin</label>
                <div className='d-flex gap-15'>
                  <div className='form-check'>
                    <input
                      id='man'
                      className='form-check-input'
                      type='radio'
                      {...formik.getFieldProps('gender')}
                      value='male'
                    />
                    <label className='form-check-label' htmlFor='man'>
                      Laki-laki
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      id='woman'
                      className='form-check-input'
                      type='radio'
                      {...formik.getFieldProps('gender')}
                      value='female'
                    />
                    <label className='form-check-label' htmlFor='woman'>
                      Perempuan
                    </label>
                  </div>
                </div>
                {formik.touched.gender && formik.errors.gender && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.gender}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Gender Input */}

              {/* begin::Submit Button */}
              <div className='d-flex justify-content-end align-items-center'>
                <button type='submit' className='btn btn-primary'>
                  {loading ? (
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  ) : (
                    'Simpan'
                  )}
                </button>
              </div>
              {/* end::Submit Button */}
            </div>
          </form>
        </div>
        {/* end::Profile Form */}

        <div className='card card-custom w-100 w-md-25 h-100'>
          <div className='card-header'>
            <div className='card-title fw-bold fs-5'>Proses Kelengkapan Data</div>
          </div>
          <div className='card-body'>
            <div
              className='progress mb-3 bg-light-primary'
              role='progressbar'
              aria-label='Basic example'
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              <div className='progress-bar w-50'></div>
            </div>
            <span className='text-gray-500'>50% Set-Up Complete</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BiodataTabs
