import {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl, KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {
  getBanks,
  getBillingAddress,
  getPersonBank,
  storeBankAccount,
  storeBillingAddress,
} from '../core/_requests'
import {useCookies} from 'react-cookie'
import clsx from 'clsx'

type Banks = {
  id: number
  sandi_bank: string
  nama_bank: string
}[]

type PersonBank = {
  id: number
  bank_name: string
  bank_account_number: string
  bank_account_owner_name: string
}[]

type BillingAddress = {
  id: number
  uuid: string
  finance_id: number
  name: string
  phone_number: string
  address: string
  bank_name: string
  bank_account_number: string
}[]

// Add account initial values
const addAccountValues = {
  bankId: 0,
  bankAccountNumber: '',
  bankAccountOwnerName: '',
}

// Add account Yup validation
const addAccountSchema = Yup.object().shape({
  bankId: Yup.number().required('Bank wajib diisi'),
  bankAccountNumber: Yup.string()
    .matches(/^[0-9]/, 'Nomor rekening hanya boleh angka')
    .required('Nomor Rekening wajib diisi'),
  bankAccountOwnerName: Yup.string().required('Nama Pemilik Rekening wajib diisi'),
})

// Billing address initial values
const billingAddressValues = {
  financeId: 0, // Bank ID (from account bank data)
  name: '',
  phoneNumber: '',
  address: '',
}

// Billing address validation schema
const billingAddressSchema = Yup.object().shape({
  financeId: Yup.number().required('Label Alamat wajib diisi'),
  name: Yup.string().required('Nama wajib diisi'),
  phoneNumber: Yup.string().required('No Handphone wajib diisi'),
  address: Yup.string().required('Alamat wajib diisi'),
})

const FinanceTab = () => {
  const [transactionRecapModal, setTransactionRecapModal] = useState(false)
  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  })
  const [cookies] = useCookies(['_bt'])
  const [addBankAccountModal, setAddBankAccountModal] = useState(false)
  const [addBillingAddressModal, setAddBillingAddressModal] = useState(false)
  const [banks, setBanks] = useState<Banks>()
  const [personBank, setPersonBank] = useState<PersonBank>()
  const [billingAddress, setBillingAddress] = useState<BillingAddress>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  // Add account formik
  const formik = useFormik({
    initialValues: addAccountValues,
    validationSchema: addAccountSchema,
    onSubmit: async (values) => {
      setLoading(true)
      setAddBankAccountModal(false)
      try {
        const {data: bankAccount} = await storeBankAccount(
          values.bankId,
          values.bankAccountNumber,
          values.bankAccountOwnerName,
          cookies._bt
        )
        setLoading(false)
        setSuccess(bankAccount.message)
        setBanks(banks)
      } catch (error: any) {
        setError(error.response.data.message)
        setLoading(false)
      }
    },
  })

  const billingAddressFormik = useFormik({
    initialValues: billingAddressValues,
    validationSchema: billingAddressSchema,
    onSubmit: async (values) => {
      setLoading(true)
      setAddBillingAddressModal(false)
      try {
        const {data: billingAddress} = await storeBillingAddress(
          values.financeId,
          values.name,
          values.phoneNumber,
          values.address,
          cookies._bt
        )
        setLoading(false)
        setSuccess(billingAddress.message)
        console.log(billingAddress)
      } catch (error: any) {
        setError(error.response.data.message)
        setLoading(false)
        console.log(error)
      }
    },
  })

  async function getBanksHandler() {
    try {
      const {data: banks} = await getBanks()
      setBanks(banks.data)
    } catch (error) {
      setBanks([{id: 0, sandi_bank: '000', nama_bank: 'bank'}])
    }
  }

  async function getPersonBankHandler() {
    try {
      const {data: personBank} = await getPersonBank(cookies._bt)
      setPersonBank(personBank.data)
    } catch (error) {
      setPersonBank([{id: 0, bank_name: '', bank_account_number: '', bank_account_owner_name: ''}])
    }
  }

  async function getBillingAddressHandler() {
    try {
      const {data: billingAddress} = await getBillingAddress(cookies._bt)
      setBillingAddress(billingAddress.data)
    } catch (error) {
      setPersonBank([{id: 0, bank_name: '', bank_account_number: '', bank_account_owner_name: ''}])
    }
  }

  // get all bank
  useEffect(() => {
    getBanksHandler()
    getPersonBankHandler()
    getBillingAddressHandler()
  }, [])

  return (
    <>
      <div className='tab-pane fade' id='tab_pane_4' role='tabpanel'>
        {/* begin::Success Message */}
        {success && (
          <div
            className='alert alert-success d-flex justify-content-between align-items-center'
            role='alert'
          >
            <div className='d-flex align-items-center gap-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
              <div className='text-dark'>{success}</div>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setSuccess('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        )}
        {/* end::Success Message */}

        {/* begin::Error Message */}
        {error && (
          <div
            className='alert alert-danger d-flex justify-content-between align-items-center'
            role='alert'
          >
            <div className='d-flex align-items-center gap-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{error}</div>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setError('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        )}
        {/* end::Error Message */}

        <div className='card card-custom mb-10'>
          <div className='card-body'>
            <div className='d-flex justify-content-end mb-10'>
              <button
                className='btn btn-icon btn-outline btn-outline-primary btn-active-light-primary border-2 w-auto px-5'
                onClick={() => setTransactionRecapModal(true)}
              >
                <i className='fa-solid fa-receipt fs-2 me-2'></i> Rekap Transaksi
              </button>
            </div>
            <div className='mb-5'>
              <div className='fs-4 fw-bold'>Rekening Bank</div>
              <div className=''>
                Penambahan akun bank mengacu kepada <a href='#'>syarat dan ketentuan</a> yang
                berlaku.
              </div>
            </div>
            <div className='d-flex justify-content-end mb-10'>
              <button
                className='btn btn-icon btn-primary  w-auto px-5'
                onClick={() => setAddBankAccountModal(true)}
              >
                Tambah Rekening
              </button>
            </div>

            {/* begin::Bank List */}
            <div className=''>
              {personBank?.map(
                (
                  {
                    id,
                    bank_name: name,
                    bank_account_number: accountNumber,
                    bank_account_owner_name: ownerName,
                  },
                  index
                ) => (
                  <div key={id} className='d-flex align-items-center gap-5 mb-5'>
                    <div className=''>
                      <img
                        className='rounded-circle'
                        src={toAbsoluteUrl('/media/diawan/visa.svg')}
                        alt=''
                      />
                    </div>
                    <div className=''>
                      <div className=''>
                        {name}{' '}
                        {index == 0 && (
                          <span className='badge badge-primary  rounded-pill'>Utama</span>
                        )}
                      </div>
                      <div className='fs-4 fw-semibold'>{accountNumber}</div>
                      <div>{ownerName}</div>
                      <div className='fw-bold'>
                        {index == 0 && (
                          <>
                            <a href='#'>Jadikan Utama</a>
                            <div className='vr mx-2'></div>
                          </>
                        )}
                        <a href='#'>Ubah</a>
                        <div className='vr mx-2'></div>
                        <a href='#'>Hapus</a>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            {/* end::Bank List */}
          </div>
        </div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div className='mb-5'>
              <div className='fs-4 fw-bold'>Alamat Penagihan</div>
              <div className=''>
                Penambahan alamat mengacu kepada <a href='#'>syarat dan ketentuan</a> yang berlaku.
              </div>
            </div>
            <div className='d-flex justify-content-end mb-5'>
              <button
                className='btn btn-icon btn-primary  w-auto px-5'
                onClick={() => setAddBillingAddressModal(true)}
              >
                Tambah Alamat
              </button>
            </div>
            {billingAddress?.map(({id, phone_number: phoneNumber, name, address}) => (
              <div key={id} className='mb-5'>
                {/* <div className='d-flex align-itmes-center'>
                    <span className='fs-4 fw-bold me-2'>Rumah</span>
                    <span className='badge badge-primary  rounded-pill'>Utama</span>
                  </div> */}
                <div className=''>{name}</div>
                <div className=''>{phoneNumber}</div>
                <div className=''>{address}</div>
                <div className='fw-bold'>
                  <a href='#'>Ubah Alamat</a>
                  <div className='vr mx-2'></div>
                  <a href='#'>Hapus</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* begin:: Transaction Recap Modal */}
      <Modal show={transactionRecapModal} className='modal-xl'>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Rekap Transaksi</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setTransactionRecapModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <button className='btn btn-white text-primary my-10'>
            <i className='fa fa-arrow-down icon-xs text-primary'></i> Export
          </button>
          <div className='d-flex justify-content-between align-items-center gap-5'>
            <div className='input-group input-group-solid form-control'>
              <span className='input-group-text bg-transparent border-0' id='basic-addon2'>
                <KTIcon iconName='magnifier' iconType='solid' className='fs-1 cursor-pointer' />
              </span>
              <input
                type='text'
                placeholder='Cari nomor transaksi'
                className='form-control bg-transparent border-0'
              />
            </div>
            <div className='w-auto'>
              <label className='input-group form-control cursor-pointer p-0' htmlFor='date-picker'>
                <Flatpickr
                  id='transaction-recap-date'
                  value={dateRange.date}
                  onChange={([startDate, endDate]) => {
                    setDateRange({startDate, endDate})
                  }}
                  options={{
                    mode: 'range',
                  }}
                  className='form-control cursor-pointer border-0'
                  placeholder='Pick date'
                />
                <span className='input-group-text bg-transparent border-0' id='basic-addon2'>
                  <KTIcon iconName='calendar' iconType='solid' className='fs-1 cursor-pointer' />
                </span>
              </label>
            </div>
          </div>
          <div className='py-5'>
            <table className='table table-row-dashed table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-8 text-gray-80 text-uppercase'>
                  <th>nomor transaksi</th>
                  <th>deskripsi</th>
                  <th>nilai</th>
                  <th>tanggal</th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, i) => (
                  <tr key={e}>
                    <td>{`INV/20231210/DW-2262${e} `}</td>
                    <td>Lorem ipsum dolor sit amet</td>
                    <td className='text-success'>{`Rp${e}.000.000`}</td>
                    <td>{`${e} Okt 2023 13:07:27`}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* begin:Pagination */}
            <div className='d-flex justify-content-end align-items-center gap-5'>
              <div className='d-flex align-items-center'>
                <div className='fs-7 me-1 text-gray-600'>Rows per-pages :</div>
                <select className='form-select w-auto border-0'>
                  <option value='10'>10</option>
                </select>
              </div>
              <div className='fs-7'>1-10 of 10</div>
              <div className='d-flex flex-wrp py-2 mr-3'>
                <a href='#' className='btn btn-icon btn-sm btn-white mr-2 my-1'>
                  <i className='fa fa-chevron-left icon-xs text-dark'></i>
                </a>
                <a href='#' className='btn btn-icon btn-sm btn-white mr-2 my-1'>
                  <i className='fa fa-chevron-right icon-xs text-dark'></i>
                  <i className='fa fa-chevron-right icon-xs text-dark'></i>
                </a>
              </div>
            </div>
            {/* end:Pagination */}
          </div>
        </div>
      </Modal>
      {/* end:: Transaction Recap Modal */}

      {/* begin:: Add Bank Account Modal */}
      <Modal show={addBankAccountModal}>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Tambah Rekening Bank</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setAddBankAccountModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <form className='form w-100' noValidate onSubmit={formik.handleSubmit}>
          <div className='modal-body'>
            <div className='mb-5'>
              <div className='form-floating'>
                <select className='form-select' {...formik.getFieldProps('bankId')}>
                  <option value=''>Pilih Bank</option>
                  {banks?.map(({id, nama_bank: bankName}) => (
                    <option key={id} value={id}>
                      {bankName}
                    </option>
                  ))}
                </select>
                <label htmlFor='old-email-input' className='form-label'>
                  Nama Bank
                </label>
              </div>
              {formik.touched.bankId && formik.errors.bankId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bankId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-5'>
              <div className='form-floating'>
                <input
                  id='bank-account-number'
                  type='text'
                  placeholder='123456789'
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid':
                        formik.touched.bankAccountNumber && formik.errors.bankAccountNumber,
                    },
                    {
                      'is-valid':
                        formik.touched.bankAccountNumber && !formik.errors.bankAccountNumber,
                    }
                  )}
                  {...formik.getFieldProps('bankAccountNumber')}
                />
                <label htmlFor='bank-account-number' className='form-label'>
                  Nomor Rekening
                </label>
              </div>
              {formik.touched.bankAccountNumber && formik.errors.bankAccountNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bankAccountNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-5'>
              <div className='form-floating'>
                <input
                  id='bank-account-name'
                  type='text'
                  placeholder='123456789'
                  className='form-control bg-transparent'
                  {...formik.getFieldProps('bankAccountOwnerName')}
                />
                <label htmlFor='bank-account-name' className='form-label'>
                  Nama Pengguna Rekening
                </label>
              </div>
              {formik.touched.bankAccountOwnerName && formik.errors.bankAccountOwnerName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bankAccountOwnerName}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='modal-footer border-0 pt-0'>
            <button type='submit' className='btn btn-primary w-100'>
              {loading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                'Tambah'
              )}
            </button>
          </div>
        </form>
      </Modal>
      {/* end:: Add Bank Account Modal */}

      {/* begin:: Add Bank Account Modal */}
      <Modal show={addBillingAddressModal}>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Tambah Alamat</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setAddBillingAddressModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <form className='form w-100' noValidate onSubmit={billingAddressFormik.handleSubmit}>
          <div className='modal-body'>
            <div className='mb-5'>
              <div className='form-floating'>
                <select
                  id='person-bank-input'
                  className='form-select'
                  {...billingAddressFormik.getFieldProps('financeId')}
                >
                  <option value=''>Pilih Bank</option>
                  {personBank?.map(({id, bank_name: bankName}) => (
                    <option key={id} value={id}>
                      {bankName}
                    </option>
                  ))}
                </select>
                <label htmlFor='person-bank-input' className='form-label'>
                  Label Alamat
                </label>
              </div>
              {billingAddressFormik.touched.financeId && billingAddressFormik.touched.financeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{billingAddressFormik.errors.financeId} </span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-5'>
              <div className='form-floating'>
                <input
                  id='person-bank-name-input'
                  type='text'
                  placeholder='name'
                  className='form-control bg-transparent'
                  {...billingAddressFormik.getFieldProps('name')}
                />
                <label htmlFor='person-bank-name-input' className='form-label'>
                  Nama
                </label>
              </div>
              {billingAddressFormik.touched.name && billingAddressFormik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{billingAddressFormik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-5'>
              <div className='form-floating'>
                <input
                  id='person-bank-phone-number-input'
                  type='text'
                  placeholder='123456789'
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid':
                        billingAddressFormik.touched.phoneNumber &&
                        billingAddressFormik.errors.phoneNumber,
                    },
                    {
                      'is-valid':
                        billingAddressFormik.touched.phoneNumber &&
                        !billingAddressFormik.errors.phoneNumber,
                    }
                  )}
                  {...billingAddressFormik.getFieldProps('phoneNumber')}
                />
                <label htmlFor='person-bank-phone-number-input' className='form-label'>
                  No Handphone
                </label>
              </div>
              {billingAddressFormik.touched.phoneNumber && billingAddressFormik.errors.phoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{billingAddressFormik.errors.phoneNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-5'>
              <div className='form-floating'>
                <textarea
                  id='person-bank-address-input'
                  placeholder='address'
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid':
                        billingAddressFormik.touched.address && billingAddressFormik.errors.address,
                    },
                    {
                      'is-valid':
                        billingAddressFormik.touched.address &&
                        !billingAddressFormik.errors.address,
                    }
                  )}
                  {...billingAddressFormik.getFieldProps('address')}
                />
                <label htmlFor='person-bank-phone-number-input' className='form-label'>
                  Alamat
                </label>
              </div>
              {billingAddressFormik.touched.address && billingAddressFormik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{billingAddressFormik.errors.address}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='modal-footer border-0 pt-0'>
            <button type='submit' className='btn btn-primary w-100'>
              {loading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                'Tambah'
              )}
            </button>
          </div>
        </form>
      </Modal>
      {/* end:: Add Bank Account Modal */}
    </>
  )
}

export default FinanceTab
