import {KTSVG, KTIcon} from '../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import OtpInput from 'react-otp-input'
import {
  API_TOKEN,
  FINANCIAL_EMAIL_URL,
  FINANCIAL_PHONE_URL,
  JOB_EMAIL_URL,
  JOB_PHONE_URL,
  MARKETING_EMAIL_URL,
  MARKETING_PHONE_URL,
  MEDIA_COMMUNICATION_EMAIL_URL,
  MEDIA_COMMUNICATION_PHONE_URL,
  UPDATE_TIMEZONE_URL,
  changeEmail,
  changePassword,
  changePhoneNumber,
  checkOtpOldEmail,
  getCurrencies,
  getTimezone,
  sendOtpNewEmail,
} from '../core/_requests'
import {Modal, Button} from 'react-bootstrap'
import {useCookies} from 'react-cookie'
import {sendOtpOldEmail} from '../core/_requests'
import DeactivateAccount from './modals/DeactivateAccount'
import axios from 'axios'

type Props = {
  data: {
    email: string
    phone_number: string
  }
  token: string
}

const AccountTab = (props: Props) => {
  const [verificationModal, setVerificationModal] = useState(false)
  const [verificationMethod, setVerificationMethod] = useState('')
  const [verificationMethodValue, setVerificationMethodValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [verificationInputModal, setVerificationInputModal] = useState(false)
  const [timezone, setTimezone] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [cookies] = useCookies(['_bt'])
  const [otp, setOtp] = useState('')
  const [checkOtpErrorMsg, setCheckOtpErrorMsg] = useState('')
  const [changeEmailModal, setChangeEmailModal] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [otpNewEmailModal, setOtpNewEmailModal] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [changePhoneModal, setChangePhoneModal] = useState(false)
  const [newPhoneNumber, setNewPhoneNumber] = useState('')
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [errorChangePassword, setErrorChangePassword] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        const {data: timezone} = await getTimezone()
        const {data: currencies} = await getCurrencies()
        setTimezone(timezone.data)
        setCurrencies(currencies.data)
      } catch (error) {
        console.log('data gagal diambil')
      }
    })()
  }, [])

  // send OTP handler function
  async function sendOtpHandler(value: string) {
    setOtp(value)
    if (value.length == 6) {
      setVerificationInputModal(false)
      setOtp('')
      try {
        const {data} = await checkOtpOldEmail(verificationMethodValue, value, cookies._bt)
        if (verificationMethod == 'email') {
          setChangeEmailModal(true)
        } else if (verificationMethod == 'phone') {
          setChangePhoneModal(true)
        } else {
          setChangePasswordModal(true)
        }
      } catch (error: any) {
        setVerificationInputModal(true)
        setCheckOtpErrorMsg(error.response.data.message)
      }
    }
  }

  // send email handler function
  async function sendEmailHandler() {
    setLoading(true)
    setCheckOtpErrorMsg('')
    try {
      const {data} = await sendOtpOldEmail(verificationMethodValue, cookies._bt)
      setLoading(false)
      setVerificationInputModal(true)
      setVerificationModal(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function sendNewEmailHandler() {
    setLoading(true)
    setCheckOtpErrorMsg('')
    try {
      const {data} = await sendOtpNewEmail(newEmail, cookies._bt)
      setLoading(false)
      setChangeEmailModal(false)
      setOtpNewEmailModal(true)
    } catch (error) {
      setLoading(false)
    }
  }

  async function sendNewOtpHandler(value: string) {
    setOtp(value)
    if (value.length == 6) {
      setOtp('')
      setOtpNewEmailModal(false)
      try {
        const {data} = await changeEmail(newEmail, value, cookies._bt)
        setSuccess(data.message)
      } catch (error: any) {
        setOtpNewEmailModal(true)
        setCheckOtpErrorMsg(error.response.data.message)
      }
    }
  }

  async function changePhoneNumberHandler() {
    setLoading(true)
    setChangePhoneModal(false)
    try {
      const {data} = await changePhoneNumber(newPhoneNumber, cookies._bt)
      setSuccess(data.message)
      setLoading(false)
    } catch (error: any) {
      setError(error.response.data.message)
      setLoading(false)
    }
  }

  async function changePasswordHandler() {
    setLoading(true)
    setChangePasswordModal(false)
    if (newPassword == newPasswordConfirmation) {
      try {
        const {data} = await changePassword(newPassword, newPasswordConfirmation, cookies._bt)
        setSuccess(data.message)
        setLoading(false)
      } catch (error: any) {
        setErrorChangePassword(error.response.data.message)
        setLoading(false)
      }
    } else {
      setErrorChangePassword('Konfirmasi password tidak sesuai')
    }
  }

  function postRequestHandler(apiUrl: string, param: object) {
    axios
      .post(apiUrl, param, {
        headers: {
          'x-api-token': API_TOKEN,
          Authorization: `Bearer ${cookies._bt}`,
        },
      })
      .then((res) => {
        console.log(res)
        setSuccess(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        setError(err.response.data.message)
      })
  }

  return (
    <>
      <div className='tab-pane fade' id='tab_pane_2' role='tabpanel'>
        {/* begin::Success Message */}
        {success && (
          <div
            className='alert alert-success d-flex justify-content-between align-items-center'
            role='alert'
          >
            <div className='d-flex align-items-center gap-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen043.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
              <div className='text-dark'>{success}</div>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setSuccess('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        )}
        {/* end::Success Message */}

        {/* begin::Error Message */}
        {error && (
          <div
            className='alert alert-danger d-flex justify-content-between align-items-center'
            role='alert'
          >
            <div className='d-flex align-items-center gap-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{error}</div>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setError('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
        )}
        {/* end::Error Message */}

        <div className='d-flex flex-wrap-reverse flex-md-nowrap gap-15'>
          {/* begin::Left Section */}
          <section className='w-100 w-md-75'>
            <div className='card card-custom mb-10'>
              <div className='card-body'>
                <div className='d-flex align-items-center gap-5 mb-5'>
                  <div className='form-floating w-100'>
                    <input
                      id='email-input'
                      type='text'
                      placeholder='email'
                      className='form-control bg-transparent'
                      value={props.data.email}
                      readOnly
                      disabled
                    />
                    <label htmlFor='email-input' className='form-label'>
                      Email
                    </label>
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-secondary'
                      onClick={() => {
                        setVerificationModal(true)
                        setVerificationMethod('email')
                      }}
                    >
                      Ubah
                    </button>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-5 mb-5'>
                  <div className='form-floating w-100'>
                    <input
                      id='phone-number-input'
                      type='text'
                      placeholder='email'
                      className='form-control bg-transparent'
                      value={props.data.phone_number || 'data tidak ditemukan'}
                      readOnly
                      disabled
                    />
                    <label htmlFor='phone-number-input' className='form-label'>
                      No Handphone
                    </label>
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-secondary'
                      onClick={() => {
                        setVerificationModal(true)
                        setVerificationMethod('phone')
                      }}
                    >
                      Ubah
                    </button>
                  </div>
                </div>

                <button
                  className='btn btn-light-warning mb-15 border fw-bold border-2 border-warning w-100'
                  data-bs-target='#verification-modal'
                  data-bs-toggle='modal'
                >
                  Ubah Kata Sandi
                </button>

                <DeactivateAccount data={props.data} />
              </div>
            </div>
            <div className='card card-custom'>
              <div className='card-body'>
                <div className='mb-20'>
                  <div className='fs-4 fw-bold mb-10'>Pengaturan Umum</div>

                  <div className='mb-5'>
                    <label className='fs-6 fw-medium text-dark mb-4 required'>
                      Media Komunikasi
                    </label>
                    <div className='d-flex gap-5'>
                      <div className='form-check'>
                        <input
                          id='communication-media-phone'
                          className='form-check-input'
                          type='checkbox'
                          value='phone'
                          onChange={(e) =>
                            postRequestHandler(MEDIA_COMMUNICATION_PHONE_URL, {
                              is_can_be_called: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='communication-media-phone'>
                          Telepon
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          id='communication-media-phone'
                          className='form-check-input'
                          type='checkbox'
                          value='email'
                          onChange={(e) =>
                            postRequestHandler(MEDIA_COMMUNICATION_EMAIL_URL, {
                              is_can_sent_by_email: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='communication-media-phone'>
                          Email
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* begin::Birth Place & Birth Date Input */}
                  <div className='d-flex gap-5'>
                    <div className='w-50'>
                      <div className='form-floating'>
                        <select
                          className='form-select'
                          id='timezone'
                          onChange={(e) =>
                            postRequestHandler(UPDATE_TIMEZONE_URL, {timezone: e.target.value})
                          }
                        >
                          {Object.entries(timezone).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </select>
                        <label htmlFor='timezone' className='form-label'>
                          Zona Waktu
                        </label>
                      </div>
                    </div>
                    <div className='w-50'>
                      <div className='form-floating'>
                        <select className='form-select' id='currencies'>
                          {Object.entries(currencies).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </select>
                        <label htmlFor='currencies' className='form-label'>
                          Mata Uang
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* end::Birth Place & Birth Date Input */}
                </div>

                {/* begin::Notification */}
                <div className=''>
                  <div className='fs-4 fw-bold mb-10'>Notifikasi</div>
                  <div className='d-flex justify-content-between align-items-center mb-5'>
                    <label className='fs-6 fw-medium text-dark mb-4'>Informasi Keuangan</label>
                    <div className='d-flex gap-5'>
                      <div className='form-check'>
                        <input
                          id='financial-email-information'
                          className='form-check-input'
                          type='checkbox'
                          onChange={(e) =>
                            postRequestHandler(FINANCIAL_EMAIL_URL, {
                              is_financial_info_by_email: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='financial-email-information'>
                          Email
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          id='financial-phone-information'
                          className='form-check-input'
                          type='checkbox'
                          onChange={(e) =>
                            postRequestHandler(FINANCIAL_PHONE_URL, {
                              is_financial_info_by_phone: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='financial-phone-information'>
                          Telepon
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mb-5'>
                    <label className='fs-6 fw-medium text-dark mb-4'>Informasi Pekerjaan</label>
                    <div className='d-flex gap-5'>
                      <div className='form-check'>
                        <input
                          id='job-email-information'
                          className='form-check-input'
                          type='checkbox'
                          onChange={(e) =>
                            postRequestHandler(JOB_EMAIL_URL, {
                              is_job_info_by_email: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='job-email-information'>
                          Email
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          id='job-phone-information'
                          className='form-check-input'
                          type='checkbox'
                          onChange={(e) =>
                            postRequestHandler(JOB_PHONE_URL, {
                              is_job_info_by_phone: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='job-phone-information'>
                          Telepon
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mb-5'>
                    <label className='fs-6 fw-medium text-dark mb-4'>Informasi Marketing</label>
                    <div className='d-flex gap-5'>
                      <div className='form-check'>
                        <input
                          id='marketing-email-information'
                          className='form-check-input'
                          type='checkbox'
                          onChange={(e) =>
                            postRequestHandler(MARKETING_EMAIL_URL, {
                              is_marketing_info_by_email: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='marketing-email-information'>
                          Email
                        </label>
                      </div>
                      <div className='form-check'>
                        <input
                          id='marketing-phone-information'
                          className='form-check-input'
                          type='checkbox'
                          onChange={(e) =>
                            postRequestHandler(MARKETING_PHONE_URL, {
                              is_marketing_info_by_phone: e.target.checked,
                            })
                          }
                        />
                        <label className='form-check-label' htmlFor='marketing-phone-information'>
                          Telepon
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Notification */}
              </div>
            </div>
          </section>
          {/* end::Left Section */}

          {/* begin::Right Section */}
          <section className='w-100 w-md-25'>
            <div className='card card-custom'>
              <div className='card-header'>
                <div className='card-title fw-bold fs-5'>Proses Kelengkapan Data</div>
              </div>
              <div className='card-body'>
                <div
                  className='progress mb-3 bg-light-primary'
                  role='progressbar'
                  aria-label='Basic example'
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div className='progress-bar w-50'></div>
                </div>
                <span className='text-gray-500'>50% Set-Up Complete</span>
              </div>
            </div>
          </section>
          {/* end::Right Section */}
        </div>
      </div>

      {/* begin::Message Verification Modal */}
      <Modal show={verificationModal} onHide={() => setVerificationModal(false)}>
        <Modal.Header className='d-block'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h5 className='modal-title'>Pilih Metode Verifikasi</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => setVerificationModal(false)}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='fs-7 text-gray-600'>
            Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi.
          </div>
        </Modal.Header>
        <div className='modal-body'>
          <div className='form-check form-check-custom form-check-solid mb-5'>
            <input
              className='form-check-input disabled'
              type='radio'
              name='verification-method'
              value=''
              id='verification-via-phone'
              disabled
            />
            <label className='form-check-label' htmlFor='verification-via-phone'>
              <div className='d-flex gap-3'>
                <KTIcon iconName='message-text-2' iconType='solid' className='text-primary fs-3x' />
                <div className=''>
                  <div>SMS ke</div>
                  <div className='fs-7 text-gray-600'>****-****-*123</div>
                </div>
              </div>
            </label>
          </div>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='radio'
              name='verification-method'
              value={props.data.email}
              id='verification-via-email'
              onChange={(e) => setVerificationMethodValue(e.target.value)}
              // onClick={(value) => verifiMethodHandler(value)}
            />
            <label className='form-check-label' htmlFor='verification-via-email'>
              <div className='d-flex gap-3'>
                <KTIcon iconName='sms' iconType='solid' className='text-primary fs-3x' />
                <div className=''>
                  <div>Email ke</div>
                  <div className='fs-7 text-gray-600'>{props.data.email}</div>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={sendEmailHandler}
            // onClick={() => {
            //   setVerificationInputModal(true)
            //   setVerificationModal(false)
            // }}
            disabled={!verificationMethodValue}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              'Selanjutnya'
            )}
          </button>
        </div>
      </Modal>
      {/* end::Message Verification Modal */}

      {/* begin::Input Verification Modal */}
      <Modal show={verificationInputModal}>
        <Modal.Header className='d-block text-center'>
          <KTIcon iconName='sms' iconType='solid' className='text-primary fs-3x' />
          <h5 className='modal-title'>Masukkan kode verifikasi</h5>
          <div className='fs-7'>
            Kode verifikasi telah dikirim melalui email ke {props.data.email}
          </div>
        </Modal.Header>
        <div className='modal-body'>
          {/* begin::Error Message */}
          {checkOtpErrorMsg && (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{checkOtpErrorMsg}</div>
            </div>
          )}
          {/* end::Error Message */}

          <div className='mb-5'>
            <label className='mb-2 text-dark fw-semibold'>Kode Verifikasi</label>
            <OtpInput
              value={otp}
              onChange={(value) => sendOtpHandler(value)}
              numInputs={6}
              renderSeparator={<span className='mx-4'>-</span>}
              renderInput={(props) => <input {...props} className='form-control w-50px h-50px' />}
            />
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={sendEmailHandler}
            // disabled={!otpCountdown}
            // disabled={true}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              'Kirim Ulang'
            )}
          </button>
        </div>
      </Modal>
      {/* end::Input Verification Modal */}

      {/* begin::Change Email Modal */}
      <Modal show={changeEmailModal}>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Ubah Email</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setChangeEmailModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='old-email-input'
                type='email'
                placeholder='testing@example.com'
                className='form-control bg-transparent'
                value={props.data.email}
                readOnly
                disabled
              />
              <label htmlFor='old-email-input' className='form-label'>
                Email Lama
              </label>
            </div>
          </div>
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='new-email-input'
                type='email'
                placeholder='testing@example.com'
                className='form-control bg-transparent'
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <label htmlFor='new-email-input' className='form-label'>
                Email Baru
              </label>
            </div>
            <div className='fs-7 mt-1 ms-2 text-gray-600'>
              Kami akan mengirimkan kode verifikasi melalui email
            </div>
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button className='btn btn-primary w-100' onClick={sendNewEmailHandler}>
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              'Lanjut'
            )}
          </button>
        </div>
      </Modal>
      {/* end::Change Email Modal */}

      {/* begin::Change Phone Modal */}
      <Modal show={changePhoneModal}>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Ubah No Handphone</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setChangePhoneModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='old-email-input'
                type='email'
                placeholder='testing@example.com'
                className='form-control bg-transparent'
                value={props.data.phone_number}
                readOnly
                disabled
              />
              <label htmlFor='old-email-input' className='form-label'>
                No Handphone Lama
              </label>
            </div>
          </div>
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='new-email-input'
                type='email'
                placeholder='testing@example.com'
                className='form-control bg-transparent'
                onChange={(e) => setNewPhoneNumber(e.target.value)}
              />
              <label htmlFor='new-email-input' className='form-label'>
                No Handphone Baru
              </label>
            </div>
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button
            className='btn btn-primary w-100'
            onClick={changePhoneNumberHandler}
            disabled={!newPhoneNumber}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              'Lanjut'
            )}
          </button>
        </div>
      </Modal>
      {/* end::Change Phone Modal */}

      {/* begin::Input Verification New Email Modal */}
      <Modal show={otpNewEmailModal}>
        <Modal.Header className='d-block text-center'>
          <KTIcon iconName='sms' iconType='solid' className='text-primary fs-3x' />
          <h5 className='modal-title'>Masukkan kode verifikasi</h5>
          <div className='fs-7'>Kode verifikasi telah dikirim melalui email ke {newEmail}</div>
        </Modal.Header>
        <div className='modal-body'>
          {/* begin::Error Message */}
          {checkOtpErrorMsg && (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{checkOtpErrorMsg}</div>
            </div>
          )}
          {/* end::Error Message */}

          <div className='mb-5'>
            <label className='mb-2 text-dark fw-semibold'>Kode Verifikasi</label>
            <OtpInput
              value={otp}
              onChange={(value) => sendNewOtpHandler(value)}
              numInputs={6}
              renderSeparator={<span className='mx-4'>-</span>}
              renderInput={(props) => <input {...props} className='form-control w-50px h-50px' />}
            />
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={sendNewEmailHandler}
            // disabled={!otpCountdown}
            // disabled={true}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              'Kirim Ulang'
            )}
          </button>
        </div>
      </Modal>
      {/* end::Input Verification Modal */}

      {/* begin::Change Phone Modal */}
      <Modal show={changePasswordModal}>
        <div className='modal-header border-0 pb-0'>
          <h5 className='modal-title'>Ubah No Handphone</h5>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={() => setChangePasswordModal(false)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          {/* begin::Error Message */}
          {errorChangePassword && (
            <div className='alert alert-danger d-flex align-items-center gap-3' role='alert'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2x svg-icon-danger'
              />
              <div className='text-dark'>{errorChangePassword}</div>
            </div>
          )}
          {/* end::Error Message */}
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='new-password-input'
                type='password'
                placeholder='pass'
                className='form-control bg-transparent'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label htmlFor='new-password-input' className='form-label'>
                Kata Sandi
              </label>
            </div>
          </div>
          <div className='mb-5'>
            <div className='form-floating'>
              <input
                id='new-password-confirmation-input'
                type='password'
                placeholder='pass'
                className='form-control bg-transparent'
                value={newPasswordConfirmation}
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              />
              <label htmlFor='new-password-confirmation-input' className='form-label'>
                Kata Sandi
              </label>
            </div>
          </div>
        </div>
        <div className='modal-footer border-0 pt-0'>
          <button
            className='btn btn-primary w-100'
            onClick={changePasswordHandler}
            disabled={!newPassword || !newPasswordConfirmation}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            ) : (
              'Lanjut'
            )}
          </button>
        </div>
      </Modal>
      {/* end::Change Phone Modal */}
    </>
  )
}

export default AccountTab
